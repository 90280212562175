<template>
  <vs-row>
    <vs-col>
      <!-- 個人資訊 -->
      <vx-card :title="$t('profile.title')" class="cd-form-group">
        <!-- Avatar -->
        <vs-row>
          <!-- Avatar Col -->
          <vs-col vs-lg="2" vs-sm="2" vs-xs="12" id="avatar-col" vs-type="flex" :vs-justify="windowWidth>=768?'flex-start':'center'">
            <div class="img-container" style="width: 80px;">
              <img v-if="activeUserImg" :src="activeUserImg" width="80" height="80" class="rounded-full w-full"/>
            </div>
          </vs-col>
          <vs-divider v-show="windowWidth<768" />
          <!-- Information - Col 1 -->
          <vs-col class="sm:pl-3 pl-0" vs-lg="5" vs-sm="5" vs-xs="12" id="account-info-col-1">
            <vs-row>
              <!-- 身分證字號 -->
              <vs-col vs-w="4" vs-xs="6">
                <p class="cd-text-muted">{{$t('profile.id')}}</p>
              </vs-col>
              <vs-col vs-w="5" vs-xs="6">
                <p>{{ myProfile.account }}</p>
              </vs-col>
              <!-- 姓名 -->
              <vs-col vs-w="4" vs-xs="6">
                <p class="cd-text-muted">{{$t('profile.name')}}</p>
              </vs-col>
              <vs-col vs-w="5" vs-xs="6">
                <p>{{ myProfile.family_name }}{{ myProfile.name }}</p>
              </vs-col>
              <!-- 信箱 -->
              <vs-col vs-w="4" vs-xs="4">
                <p class="cd-text-muted">{{$t('profile.email')}}</p>
              </vs-col>
              <vs-col vs-w="5" vs-xs="8">
                <p>{{ myProfile.email }}</p>
              </vs-col>
              <!-- 通知 -->
              <!-- <vs-col vs-w="4">
                <p class="font-semibold">{{$t('Notify')}}: </p>
              </vs-col>
              <vs-col vs-w="8" vs-type="flex" vs-align="center"> -->
                <!-- 綁定LINE 按鈕 -->
                <!-- <vs-button class="px-3 mr-1" size="small" type="filled" color="warning" icon="link" @click="chosen_notify_chat" v-if="myProfile.line_notify_access_token==''||myProfile.line_notify_access_token==null">{{ $t('BindLINE') }}</vs-button>
                <vs-button class="px-3 mr-1" size="small" type="filled" color="danger" icon="cancel" @click="close_revoke_line_notify" v-else>{{ $t('unbindLINE') }}</vs-button> -->
                <!-- 綁定成功/尚未綁定 -->
                <!-- <vs-chip color="success" v-if="myProfile.line_notify_access_token!='' && myProfile.line_notify_access_token!=null" style="float:right;">{{ $t('bindSuccess') }}</vs-chip>
                <vs-chip color="danger" v-else style="float:right;">{{ $t('bindNotYet') }}</vs-chip>
              </vs-col> -->
            </vs-row>
          </vs-col>
          <vs-divider v-show="windowWidth<768" />
          <!-- Information - Col 2 -->
          <vs-col class="sm:pl-3 pl-0" vs-lg="5" vs-sm="5" vs-xs="12" id="account-info-col-2">
            <vs-row>
              <!-- 審核狀態 -->
              <vs-col vs-w="4" vs-xs="6">
                <p class="cd-text-muted">{{$t('profile.doctorStatus')}}</p>
              </vs-col>
              <vs-col vs-w="5" vs-xs="6">
                <p>{{ getDoctorEnableText(doctorEnable) }}</p>
              </vs-col>
              <!-- 醫事類別 -->
              <vs-col vs-w="4" vs-xs="6">
                <p class="cd-text-muted">{{$t('profile.privileges')}}</p>
              </vs-col>
              <vs-col vs-w="5" vs-xs="6">
                <p>{{ $i18n.locale=='en' ? myProfile.ms_ename : myProfile.ms_cname }}</p>
              </vs-col>
              <!-- 職稱 -->
              <vs-col vs-w="4" vs-xs="6">
                <p class="cd-text-muted">{{$t('profile.title')}}</p>
              </vs-col>
              <vs-col vs-w="5" vs-xs="6">
                <p>{{ $i18n.locale=='en' ? myProfile.title_ename : myProfile.title_cname }}</p>
              </vs-col>
              <!-- 手機號碼 -->
              <vs-col vs-w="4" vs-xs="6">
                <p class="cd-text-muted">{{$t('profile.mobile')}}</p>
              </vs-col>
              <vs-col vs-w="5" vs-xs="6">
                {{ myProfile.mobile }}
              </vs-col>
              <vs-col vs-w="8" vs-offset="4" vs-type="flex" vs-align="center">
                <!-- 手機驗證 按鈕 -->
                <vs-button class="cd-button-4w-icon mr-1" type="border" icon="shield" @click="showChangePhone">{{$t('profile.mobileVerify')}}</vs-button>
                <!-- 已驗證/未驗證 -->
                <vs-chip style="white-space: nowrap;" v-if="myProfile.mobile!=''">{{$t('profile.mobilePass')}}</vs-chip>
                <vs-chip style="white-space: nowrap;" v-else>{{$t('profile.mobileNotPass')}}</vs-chip>
              </vs-col>
            </vs-row>
          </vs-col>
          <vs-divider />
          <!-- 編輯，修改密碼按鈕 -->
          <vs-row>
            <vs-col vs-type="flex" vs-justify="center">
              <vs-button type="border" class="cd-button-2w-icon mr-2" icon="icon-edit" icon-pack="feather"  @click.stop="showEditProfile">    {{$t('profile.edit')}}  </vs-button>
              <vs-button type="border" class="cd-button-4w-icon"      icon="lock"                           @click.stop="showChangePassword"> {{$t('profile.resetPassword')}}</vs-button>
            </vs-col>
          </vs-row>
        </vs-row>
      </vx-card>
      <!-- 服務內容卡片 -->
      <vx-card class="cd-form-group">
        <!-- 服務單位 -->
        <div class="vx-row mb-base">
          <h5 class="mb-2 mx-4">{{$t('profile.serviceUnit')}}</h5>
          <vs-row class="mx-4">
            <!-- 服務單位 -->
            <vs-col  vs-w="7" vs-lg="7" vs-sm="7" vs-xs="12" vs-type="flex">
              <vx-input-group class=" w-full">
                <template slot="prepend">
                </template>
                <!-- 顯示合作夥伴 -->
                <vs-input v-model="serviceUnit.name" disabled class="mb-2">
                </vs-input>
              </vx-input-group>
              <!--呈現綁定合作夥伴可進行取消-->
              <vs-button color="dark" size="large" radius type="flat" icon="delete" @click="close_serviceUnit_name(serviceUnit.name)" :disabled="serviceUnit.name!=null?false:true"></vs-button>
            </vs-col>
            <!-- 搜尋 -->
            <vs-col vs-w="5" vs-lg="5" vs-sm="5" vs-xs="12" >
              <vx-auto-suggest :data="pages" />
            </vs-col>
          </vs-row>
        </div>
        <!-- 服務方式 -->
        <div class="vx-row mb-base">
          <div class="vx-col w-full">
            <h5 class="mb-2">{{$t('profile.serviceType')}}</h5>
            <v-select multiple :closeOnSelect="false" v-model="myService" :options="options1" label="label" :dir="$vs.rtl ? 'rtl' : 'ltr'" @input="Service"/>
          </div>
        </div>
        <!-- 服務列表 -->
        <div class="vx-row mb-base">
          <div class="vx-col w-full">
            <h5 class="mb-2">{{$t('profile.serviceList')}}</h5>
            <div class="flex mb-2">
              <vs-input class="text-base" v-on:keyup.13="addWord" vs-justify="start" :placeholder="$t('profile.addServiceList')" @keyup.enter="word" v-model="word"/>
              <vs-button icon="add" class="cd-button-2w-icon ml-2" type="border" :disabled="word.length==0" @click="addWord">{{$t('profile.add')}}</vs-button>
            </div>
            <!-- 服務列表資料 -->
            <perfect-scrollbar class="chat-content-scroll-area border border-solid d-theme-border-grey-light ps3 w-full" :settings="settings">
              <vs-list>
                <vs-list-item :class="windowWidth < 768 ? 'cd-list-item-sm w-full' : ''" :title="item.word" v-for="(item, index) in words" :key="index">
                  <vs-col vs-type="flex" vs-justify="flex-end">
                    <feather-icon icon="EditIcon" class="m-1 cursor-pointer hover:text-primary" @click="showEditDialog(item)"></feather-icon>
                    <feather-icon icon="Trash2Icon" class="m-1 cursor-pointer hover:text-primary" @click="delWord(item)"></feather-icon>
                  </vs-col>
                </vs-list-item>
                <vs-list-item v-show="words==''" :title="$t('profile.noData')"></vs-list-item>
              </vs-list>
            </perfect-scrollbar>
          </div>
        </div>
        <!-- 精通語言 -->
        <div class="vx-row mb-base">
          <div class="vx-col w-full">
            <h5 class="mb-2">{{$t('profile.lang')}}</h5>
            <v-select multiple :closeOnSelect="false" v-model="myServiceLang" :options="optionsLang" label="label" :dir="$vs.rtl ? 'rtl' : 'ltr'" @input="mylang"/>
          </div>
        </div>
        <!-- 每筆掛號費用設定 -->
        <vs-row>
          <vs-col>
            <h5 class="mb-2">{{$t('profile.fee')}}</h5>
          </vs-col>
          <!-- 台灣白天諮詢費率 -->
          <vs-col class="cd-form-group">
            <p>{{$t('profile.dayFee')}}: {{ twGeneralFee }} {{$t('profile.NTs')}}/{{$t('profile.queue')}}</p>
            <el-slider v-model="twGeneralFee" @change="all_slider_price_change" :step="10" :max=5000 show-input :show-input-controls="false"></el-slider>
          </vs-col>
          <!-- 台灣夜間和急診諮詢費率 -->
          <vs-col class="cd-form-group">
            <p>{{$t('profile.nightFee')}}: {{ twAdditionalFee }} {{$t('profile.NTs')}}/{{$t('profile.queue')}}</p>
            <el-slider v-model="twAdditionalFee" @change="all_slider_price_change" :step="10" :max=5000 show-input :show-input-controls="false"></el-slider>
          </vs-col>
          <!-- 大陸白天諮詢費率 -->
          <vs-col class="cd-form-group">
            <p>{{$t('profile.dayFeeChina')}}: {{ cnGeneralFee }} {{$t('profile.CNs')}}/{{$t('profile.queue')}}</p>
            <el-slider v-model="cnGeneralFee" @change="all_slider_price_change" :step="1" :max=2500 show-input :show-input-controls="false"></el-slider>
          </vs-col>
          <!-- 大陸夜間和急診諮詢費率 -->
          <vs-col class="cd-form-group">
            <p>{{$t('profile.nightFeeChina')}}: {{ cnAdditionalFee }} {{$t('profile.CNs')}}/{{$t('profile.queue')}}</p>
            <el-slider v-model="cnAdditionalFee" @change="all_slider_price_change" :step="1" :max=2500 show-input :show-input-controls="false"></el-slider>
          </vs-col>
        </vs-row>
      </vx-card>
      <!-- 科別 -->
      <vx-card :title="$t('profile.division')" class="cd-form-group">
        <!-- 編輯按鈕 -->
        <template slot="actions">
          <vs-button icon-pack="feather" icon="icon-edit" @click="showEditDivision()"></vs-button>
        </template>
        <!-- 專長清單 -->
        <div class="block overflow-x-auto">
          <div class="cd-form-group" v-for="(item,index) in division" :key="index">
            <p class="cd-text-muted">{{$i18n.locale=='en' ? item.etitle : item.title}}</p>
            <p>                     {{$i18n.locale=='en' ? item.esubtitle : item.subtitle}}</p>
            <vs-divider />
          </div>
        </div>
      </vx-card>
      <!-- 學經歷 -->
      <vx-card :title="$t('profile.educationExperience')" class="cd-form-group">
        <!-- 編輯按鈕 -->
        <template slot="actions">
          <vs-button icon-pack="feather" icon="icon-edit" @click="showEditExperience()"></vs-button>
        </template>
        <!-- 學經歷 -->
        <div class="block overflow-x-auto">
          {{$t('profile.education')}}
          <div class="cd-form-group">
            <!-- <p class="cd-text-muted">{{switch_experience(item.title)}}</p> -->
            {{ showExperience(experience[0].subtitle) }}
          </div>
          <vs-divider />
          {{$t('profile.experience')}}
          <div class="cd-form-group">
            {{ showExperience(experience[1].subtitle) }}
          </div>
        </div>
      </vx-card>
      <!-- 銀行資訊 -->
      <vx-card :title="$t('profile.bankInfo')" class="cd-form-group">
        <!-- 編輯按鈕 -->
        <template slot="actions">
          <vs-button icon-pack="feather" icon="icon-edit" @click="showEditBankData()"></vs-button>
        </template>
        <vs-row v-if="myProfile.account!=''"> <!-- 小防呆 -->
          <!-- Bank Photo -->
          <vs-col vs-lg="1" vs-sm="2" vs-xs="12" id="avatar-col" v-if="myProfile.bank_account_img">
            <div class="img-container" style="width: 80px;">
              <img :src="bankImg" width="180" height="180" class="w-full"/>
            </div>
          </vs-col>
          <!-- Information - Col 1 -->
          <vs-col class="sm:pl-3 pl-0" vs-lg="5" vs-sm="4" vs-xs="12" id="account-info-col-1">
            <vs-row>
              <vs-col vs-w="5" vs-xs="6">
                <p class="cd-text-muted">{{$t('profile.bankName')}}</p>
              </vs-col>
              <vs-col vs-w="7" vs-xs="6">
                <p v-if="myProfile.bankdata.bank_kind==0">{{ myProfile.bankdata.bank_name }}({{ myProfile.bankdata.bank_code }})</p>
                <p v-if="myProfile.bankdata.bank_kind==1">{{ myProfile.bankdata.bank_name }}</p>
              </vs-col>
              <vs-col vs-w="5" vs-xs="6">
                <p class="cd-text-muted">{{$t('profile.bankBranch')}}</p>
              </vs-col>
              <vs-col vs-w="7" vs-xs="6">
                <p>{{ myProfile.bankdata.branch }}</p>
              </vs-col>
              <vs-col vs-w="5" vs-xs="6">
                <p class="cd-text-muted">{{$t('profile.bankAddress')}}</p>
              </vs-col>
              <vs-col vs-w="7" vs-xs="6">
                <p>{{  myProfile.bankdata.address }}</p>
              </vs-col>
            </vs-row>
          </vs-col>
          <!-- Information - Col 2 -->
          <vs-col class="sm:pl-3 pl-0" vs-lg="5" vs-sm="6" vs-xs="12" id="account-info-col-2">
            <vs-row>
              <vs-col vs-w="4" vs-xs="6">
                <p class="cd-text-muted">{{$t('profile.bankAccount')}}</p>
              </vs-col>
              <vs-col vs-w="5" vs-xs="6">
                <p>{{ myProfile.bankdata.account_name }}</p>
              </vs-col>
              <vs-col vs-w="4" vs-xs="6">
                <p class="cd-text-muted">{{$t('profile.bankAccountName')}}</p>
              </vs-col>
              <vs-col vs-w="5" vs-xs="6">
                <p>{{ myProfile.bankdata.account_no }}</p>
              </vs-col>              
              <vs-col vs-w="3" vs-lg="6" vs-sm="6" vs-xs="5" v-if="myProfile.bankdata.bank_kind==1">
                <p class="cd-text-muted">{{$t('profile.bankSwiftCode')}}</p>
              </vs-col>
              <vs-col vs-w="6" vs-lg="6" vs-sm="6" vs-xs="7" v-if="myProfile.bankdata.bank_kind==1">
                {{ myProfile.bankdata.swift }}
              </vs-col>
            </vs-row>
          </vs-col>
        </vs-row>
      </vx-card>
      <!-- 其他資訊 -->
      <vx-card :title="$t('profile.otherInfo')">
        <div class="vx-row">
          <!-- 個人照片 -->
          <div class="vx-col sm:w-1/2 lg:w-1/2 w-full">
            <p class="font-semibold" style="text-align: center;">{{$t('profile.personalPhoto')}}</p>
            <avatar :action="api_url+'/api/uploadprofile/P'"/>
          </div>
          <!-- 銀行照片 -->
          <!-- <div class="vx-col sm:w-1/3 lg:w-1/3 w-full">
            <p>{{$t('doctorBank')}}</p>
            <el-upload
              class="avatar-uploader"
              :action="api_url+'/api/uploadprofile/B'"
              :show-file-list="false"
              :headers="headers"
              :on-success="handleBankSuccess"
              :before-upload="beforeBankUpload">
              <img v-if="bankImg" :src="bankImg" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <el-button type="primary" style="background-color: #003f8e">{{$t('SelectFile')}}</el-button>
            </el-upload>
          </div> -->
          <!-- 執業執照 -->
          <div class="vx-col sm:w-1/2 lg:w-1/2 w-full">
            <p class="font-semibold" style="text-align: center;">{{$t('profile.licensePhoto')}}</p>
            <img v-if="licenseImg" :src="licenseImg" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <el-upload
              class="avatar-uploader text-center"
              :action="api_url+'/api/uploadprofile/L'"
              :show-file-list="false"
              :headers="headers"
              :on-success="handleLicenseSuccess"
              :before-upload="beforeLicenseUpload">
              <el-button class="text-base" type="primary" style="background-color: #003f8e">{{$t('profile.selectFile')}}</el-button>
              <p class="my-3 mx-auto" style="color:red">
                {{$t('profile.uploadCheck')}}
            </p>
            </el-upload>
          </div>
        </div>
      </vx-card>
      <!-- 個人資訊編輯視窗 -->
      <vs-prompt
        @accept="updateProfile"
        @close="close"
        :acceptText="$t('popup.update')"
        :cancelText="$t('popup.cancel')"
        :title="$t('profile.title')"
        :active.sync="activeEditProfile"
        :is-valid="family_name!=''&&name!=''">
        <div class="cd-el-select">
          <vs-input :label="$t('profile.firstName')" v-model="family_name" class="cd-form-group w-full" name="family_name" v-validate="'required'" />
          <vs-input :label="$t('profile.lastName')" v-model="name" class="cd-form-group w-full" name="name" v-validate="'required'" />
          <vs-input :label="$t('profile.email')" v-model="email" class="cd-form-group w-full" name="email" v-validate="'email|required'" />
          <span style="padding-left: 5px; font-size: .85rem; color: rgba(0,0,0,.7);">{{ $t('profile.position') }}</span>
          <v-select
          class="w-full cd-select"
          :placeholder="myProfile.title_cname"
          autocomplete
          :clearable="false"
          v-model="selectedTitle"
          :options="medicalTitleList"
          label="name" />
        </div>
      </vs-prompt>
      <!-- 服務列表編輯視窗 -->
      <vs-prompt
        :title="$t('popup.edit')"
        :accept-text="$t('popup.update')"
        :cancel-text="$t('popup.cancel')"
        @cancel="editword=''"
        @accept="acceptEdit"
        :active.sync="activeEdit">
        <div class="con-exemple-prompt">
          <vs-input :placeholder="$t('profile.enterText')" v-model="editword"/>
        </div>
      </vs-prompt>
      <!-- 科別編輯視窗 -->
      <vs-popup
      fullscreen
        @accept="updateDivision"
        @close="close"
        :acceptText="$t('popup.update')"
        :cancelText="$t('popup.cancel')"
        :title="$t('profile.division')"
        :active.sync="activeEditDivision">
        <vs-row>
          <!-- 內科 -->
          <vs-col vs-w="3" vs-lg="3" vs-sm="6" vs-xs="12">
            <vs-list>
                <vs-list-header class="mb-4" :title="$t('profile.internalMD')"></vs-list-header>
                <ul>
                  <li class="flex" v-for="(item, index) in divisions1" :key="index">
                    <vs-switch class="mr-12 mb-2" v-model="selectDiv1" :vs-value="item.di_id.toString()" />
                    <label>{{ ($i18n.locale=='en') ? item.ename :  item.name}}</label>
                  </li>
                </ul>
            </vs-list>
          </vs-col>
          <!-- 外科 -->
          <vs-col vs-w="3" vs-lg="3" vs-sm="6" vs-xs="12">
            <vs-list>
              <vs-list-header class="mb-4" :title="$t('profile.surgical')"></vs-list-header>
                <ul>
                  <li class="flex" v-for="(item, index) in divisions2" :key="index">
                    <vs-switch class="mr-12 mb-2" v-model="selectDiv2" :vs-value="item.di_id.toString()" />
                    <label>{{ ($i18n.locale=='en') ? item.ename :  item.name}}</label>
                  </li>
                </ul>
            </vs-list>
          </vs-col>
          <!-- 其他科別 -->
          <vs-col vs-w="3" vs-lg="3" vs-sm="6" vs-xs="12">
            <vs-list>
              <vs-list-header class="mb-4" :title="$t('profile.otherSubjects')"></vs-list-header>
                <ul>
                  <li class="flex" v-for="(item, index) in divisions3" :key="index">
                    <vs-switch class="mr-12 mb-2" v-model="selectDiv3" :vs-value="item.di_id.toString()"/>
                    <label>{{ ($i18n.locale=='en') ? item.ename :  item.name}}</label>
                  </li>
                </ul>
            </vs-list>
          </vs-col>
          <!-- 中醫 -->
          <vs-col vs-w="3" vs-lg="3" vs-sm="6" vs-xs="12">
            <vs-list>
              <vs-list-header class="mb-4" :title="$t('profile.chineseMD')"></vs-list-header>
              <ul>
                  <li class="flex" v-for="(item, index) in divisions4" :key="index">
                    <vs-switch class="mr-12 mb-2" v-model="selectDiv4" :vs-value="item.di_id.toString()"/>
                    <label>{{ ($i18n.locale=='en') ? item.ename :  item.name}}</label>
                  </li>
                </ul>
            </vs-list>
          </vs-col>
          <vs-divider class="my-6" />
          <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
            <vs-button @click="updateDivision" class="mr-4">{{ $t('popup.update') }}</vs-button>
            <vs-button @click="activeEditDivision=false">{{ $t('popup.cancel') }}</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- 學經歷編輯視窗 -->
      <vs-popup
        @accept="updateExperience"
        @close="close"
        :acceptText="$t('popup.update')"
        :cancelText="$t('popup.cancel')"
        :title="$t('profile.educationExperience')"
        :active.sync="activeEditExperience">
        <vs-row vs-type="flex" vs-justify="center" vs-align="center">
          <vs-col class="p-4" vs-w="6" vs-lg="6" vs-sm="6" vs-xs="12">
            <h5>{{$t('profile.education')}}</h5>
            <div v-for="(item,index) in experience[0].subtitle" :key="index">
              <vs-input v-model="itemValues1[index]" class="mt-2 w-full"/>
            </div>


            <!-- <vs-input v-model="education1" class="mt-2 w-full" />
            <vs-input v-model="education2" class="mt-2 w-full" />
            <vs-input v-model="education3" class="mt-2 w-full" />
            <vs-input v-model="education4" class="mt-2 w-full" />
            <vs-input v-model="education5" class="mt-2 w-full" />
            <vs-input v-model="education6" class="mt-2 w-full" /> -->
          </vs-col>
          <vs-divider v-if="windowWidth <= 550" />
          <vs-col class="p-4" vs-w="6" vs-lg="6" vs-sm="6" vs-xs="12">
            <h5>{{$t('profile.experience')}}</h5>
            <div v-for="(item2,index) in experience[1].subtitle" :key="index">
              <vs-input v-model="itemValues2[index]" class="mt-2 w-full"/>
            </div>
            <!-- <vs-input v-model="experience1" class="mt-2 w-full" />
            <vs-input v-model="experience2" class="mt-2 w-full" />
            <vs-input v-model="experience3" class="mt-2 w-full" />
            <vs-input v-model="experience4" class="mt-2 w-full" />
            <vs-input v-model="experience5" class="mt-2 w-full" />
            <vs-input v-model="experience6" class="mt-2 w-full" /> -->
          </vs-col>
          <vs-divider />
          <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
            <vs-button @click="updateExperience(itemValues1,itemValues2)" class="mr-4">{{ $t('popup.update') }}</vs-button>
            <vs-button @click="activeEditExperience=false">{{ $t('popup.cancel') }}</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- 銀行資訊編輯視窗 -->
      <vs-prompt
        @accept="updateBankData"
        @close="close"
        :acceptText="$t('popup.update')"
        :cancelText="$t('popup.cancel')"
        :title="$t('profile.bankInfo')"
        :active.sync="activeEditBankData"
        :is-valid="account_name!=''&&account_no!=''&&(bankkind==1?swift!=''&&bankname!=''&&bankkind!='':true)">
        <vs-select
            :label="$t('profile.selectDomesticOrForeign')"
            v-model="bankkind">
          <vs-select-item :key="item.bank_kind" :value="item.bank_kind" :text="item.bank_kind_name" v-for="item in bankkindlist" />
        </vs-select>
        <vs-select
          v-show="bankkind==0"
          :label="$t('profile.bankName')"
          autocomplete
          v-model="bankid">
          <vs-select-item :key="index" :value="item.id" :text="item.bank+'('+item.code+')'" v-for="(item,index) in tw_all_banklist" />
        </vs-select>
        <vs-input :label="'*'+$t('profile.bankName')" v-model="bankname" class="mt-5 w-full" name="bankname" v-validate="'required'" v-show="bankkind==1" />
        <vs-input :label="$t('profile.bankBranch')" v-model="branch" class="mt-5 w-full" name="branch" v-validate="'required'" />
        <vs-input :label="$t('profile.bankAddress')" v-model="address" class="mt-5 w-full" name="address" v-validate="'required'" />
        <vs-input :label="'*'+$t('profile.bankAccount')" v-model="account_name" class="mt-5 w-full" name="account_name" v-validate="'required'" />
        <vs-input :label="'*'+$t('profile.bankAccountName')" v-model="account_no" class="mt-5 w-full" name="'account_no" v-validate="'required'" />
        <vs-input :label="'*'+$t('profile.bankSwiftCode')" v-model="swift" class="mt-5 w-full" name="swift" v-validate="'required'" v-show="bankkind==1" />
      </vs-prompt>
      <!-- 變更密碼視窗 -->
      <vs-prompt
        @cancel="newpw=''; newpwagain=''"
        @accept="updatePW"
        @close="close"
        :acceptText="$t('popup.update')"
        :cancelText="$t('popup.cancel')"
        :title="$t('profile.resetPassword')"
        :active.sync="activeChangePassword">
        <div class="con-exemple-prompt">
          <p class="mb-5 text-danger font-medium-3 text-bold-600">{{$t('profile.resetPasswordTip')}}</p>
          <p>{{$t('profile.enterNewPW')}}</p>
          <vs-input class="mt-2" :placeholder="$t('profile.resetPWPlaceholder')" v-model="newpw"/>
        </div>
        <div class="mt-5 con-exemple-prompt">
          {{$t('profile.enterNewPWAgain')}}
          <vs-input class="mt-2" :placeholder="$t('profile.resetPWPlaceholder')" v-model="newpwagain"/>
        </div>
      </vs-prompt>
      <!-- 變更手機號碼視窗 -->
      <vs-prompt
        @cancel="newphone=''; newphonevalidator='';"
        @accept="updatePH"
        @close="close"
        :acceptText="$t('popup.update')"
        :cancelText="$t('popup.cancel')"
        :title="$t('profile.changeMobile')"
        :active.sync="activeChangePhone">
        <p class="cd-form-group text-danger">{{$t('profile.changeMobileTip')}}</p>
        <!-- 手機國碼 -->
        <vs-select
          class="cd-form-group"
          autocomplete
          attach
          v-model="countryCode">
          <vs-select-item
            v-for="(item, index) in userinfo_country_code"
            :key="index"
            :value="item.phoneCode"
            :text="countryCodeTranslation(item)" />
        </vs-select>
        <!-- 手機號碼 input -->
        <vs-input class="cd-form-group" :placeholder="$t('profile.enterMobile')" v-model="newphone" v-validate="'required'"/>
        <div class="flex">
          <!-- 輸入驗證碼 -->
          <vs-input :placeholder="$t('profile.enterVerifyCode')" v-model="newphonevalidator" v-validate="'required'"/>
          <!-- 寄發驗證碼按鈕 -->
          <vs-button type="border" class="cd-button-4w ml-4" @click="getVCode" :disabled="vBtnDisable">{{vBtnText}}
            <countdown v-if="counting" :time="20 * 1000" @end="handleCountdownEnd">
              <template slot-scope="props">{{ $t('profile.timeLeft') }}：{{ props.seconds }} {{ $t('profile.seconds') }}</template>
            </countdown>         
          </vs-button>
        </div>
        <p class="text-danger" v-if="counting">{{$t('profile.invalidVerifyCodeTimeLimit')}}</p>
      </vs-prompt>
    </vs-col>
  </vs-row>
</template>

<script>
import { fetchHospital, changePassword, fetchServiceLang, editdoctorphone, deletehospitalmap,RevokeLineaccesstoken,BindLineaccesstoken,updatePersonalData, fetchAllBankList, insertMyBank, getallDivisions, updateDivision, createDoctorFee } from '@/api/user'
import VxAutoSuggest from '@/components/vx-auto-suggest/VxAutoSuggest.vue';
import vSelect from 'vue-select'
import Avatar from '@/components/Avatar'
import {countryCodes} from '@/api/countryCodes.js'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
export default {
  components: {
    Avatar,
    vSelect,
    VxAutoSuggest,
    VuePerfectScrollbar,
    PerfectScrollbar
  },
  data() {
    return {
      newpw: '',
      newpwagain: '',
      newphone:'',
      newphonevalidator:'',
      countryCode:'+886',
      counting:false,
      vBtnText:this.$t('profile.mobileVerify'),
      vBtnDisable:true,
      userinfo_country_code: countryCodes,
      activeChangePassword: false,
      activeChangePhone:false,
      activeEditProfile:false,
      activeEditBankData:false,
      activeEditExperience:false,
      activeEditDivision:false,
      access_token:'',
      select: { name: '', code: '' },
      editword: '',
      editid: 0,
      delid: 0,
      activeEdit: false,
      word: '',
      servicePage: 1,
      serviceTotal: 0,
      settings: {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.70,
      },
      options: [],
      pages: {
        key: "title",
        data: []
      },
      hospitalList: [],
      serviceLang: [],
      // 諮詢費率(舊變數)
      mygeneral_fee: null,
      myadditional_fee: null,
      mygeneral_fee_cn: null,
      myadditional_fee_cn: null,
      // 台灣白天每診費用
      twGeneralFee: null,
      // 台灣晚上急診每診費用
      twAdditionalFee: null,
      // 大陸白天每診費用
      cnGeneralFee: null,
      // 大陸晚上急診每診費用
      cnAdditionalFee: null,
      optionsLang:['繁體中文', '简体中文', 'English', 'Cambodia', 'Philippines', 'Thailand', 'Việt Nam','Bahasa Indonesia','Bahasa Melayu'],
      options1:[
        this.$t('profile.outPatient'),
        this.$t('profile.online'),
        this.$t('profile.mainlandChinaService'),
        this.$t('profile.volunteerOnline'),
        this.$t('profile.onsite')],
      input: '',
      headers: {
        'Authorization' : 'Bearer '+localStorage.getItem('di_tokenId')
      },
	    api_url:process.env.VUE_APP_API_URL_HIS,
      // 編輯 個人資訊
      name: '',
      family_name:'',
      email: '',
      // 編輯 銀行資訊
      bankname: '',
      branch: '',
      address: '',
      account_name: '',
      account_no: '',
      swift: '',
      bankid: '',
      bankcode: '',
      bankkind: 0,
      bankkindlist:[
        {bank_kind: 0, bank_kind_name: this.$t('profile.localeBank')},
        {bank_kind: 1, bank_kind_name: this.$t('profile.foreignBank')},
      ],
      tw_all_banklist:[],
      // 編輯 專長
      divisions1: [],
      divisions2: [],
      divisions3: [],
      divisions4: [],
      selectedTitle: null,
      itemValues1:[],
      itemValues2:[],
      edu:'',
      exper:''
    }
  },
  mounted() {
    this.fetchWords()
    // this.itemValues1 =this.experience[0].subtitle.map((item) => item.value);
    // this.itemValues2 = this.experience[1].subtitle.map((item) => item.value);
    // console.log(JSON.parse(localStorage.getItem('partnerlist')), this.partnerid)
    // let parnterList = JSON.parse(localStorage.getItem('partnerlist'))
    // _.find(parnterList, {'id': this.partnerid})
    // console.log(_.find(parnterList, {'id': this.partnerid}).company)
  },
  watch: {
    // 檢查手機號碼 來開關 寄發驗證碼按鈕
    newphone(n) {
      if (this.counting == false){
        if (n.length == 9 || n.length==10){
          this.vBtnDisable = false
        }else{
          this.vBtnDisable = true
        }
      }
    },
    getexperience(){
      this.itemValues1 =this.experience[0].subtitle.map((item) => item.value);
    this.itemValues2 = this.experience[1].subtitle.map((item) => item.value);
    },
    // 開啟變更手機號碼視窗時 關閉背景的scrollbar
    activeChangePhone(n){
      this.backgroundScrollbarSwitch(n)
    },
    // 開啟變更密碼視窗時 關閉背景的scrollbar
    activeChangePassword(n){
      this.backgroundScrollbarSwitch(n)
    },
    // 開啟編輯個人資訊視窗時 關閉背景的scrollbar
    activeEditProfile(n){
      this.backgroundScrollbarSwitch(n)
    },
    // 開啟編輯銀行資訊視窗時 關閉背景的scrollbar
    activeEditBankData(n){
      this.backgroundScrollbarSwitch(n)
    },
    // 開啟編輯學經歷視窗時 關閉背景的scrollbar
    activeEditExperience(n){
      this.backgroundScrollbarSwitch(n)
    },
    // 開啟編輯專長視窗時 關閉背景的scrollbar
    activeEditDivision(n){
      this.backgroundScrollbarSwitch(n)
    },
    // 銀行名稱變更時 取銀行list 並將銀行name&code加入變數
    bankid(){
      fetchAllBankList().then(resp => {
        this.tw_all_banklist = resp.data.items
        this.tw_all_banklist.filter((info)=>{
          if(info.id==this.bankid){
            this.bankname = info.bank
            this.bankcode = info.code
          }
        })
      })
    },
    // 切換 本國銀行或外國銀行時 init銀行名稱&代碼
    bankkind(n){
      if(n==1){
        this.bankid = null
        this.bankcode = null
      }
      if(n==0){
        this.bankid = 612
        this.bankcode = null
      }
    },
  },
  async created() {
    var _self = this
    await _self.$store.dispatch('fetchProfile').then(function(res){
      _self.twGeneralFee = parseInt(res.data.data[0].fee.general_fee)
      _self.twAdditionalFee = parseInt(res.data.data[0].fee.additional_fee)
      _self.cnGeneralFee = parseInt(res.data.data[0].fee.general_fee_cn)
      _self.cnAdditionalFee = parseInt(res.data.data[0].fee.additional_fee_cn)
    })
    this.$store.dispatch('getBankPhoto')
    this.$store.dispatch('getLicensePhoto')
    this.getHospital()
    this.getServiceLang()
    this.getDivisions()
    //是否有line綁定參數
    // if(this.$route.query.code){
    //   this.getbindlinenotify(this.$route.query.code)
    // }
  },
  computed: {
    doctorEnable() {
      return this.$store.state.doctorEnable
    },
    varify() {
      return this.$store.state.verify
    },
    serviceUnit() {
      return this.$store.getters.serviceUnit
    },
    activeUserImg() {
      return this.$store.getters.avatar;
    },
    bankImg() {
      return this.$store.getters.bankImage;
    },
    licenseImg() {
      return this.$store.getters.licenseImage;
    },
    doctorSelectUnit() {
      return this.$store.getters.doctorSelectUnit;
    },
    words(){
      return this.$store.getters.servicelist
    },
    myService: {
      get() {
        return this.$store.getters.myService
      },
      set() {
      }
    },
    myServiceLang: {
      get() {
        return this.$store.getters.myServiceLang
      },
      set() {
      }
    },
    doctorType() {
      return this.$store.getters.doctortype
    },
    division() {
      return this.$store.getters.division
    },
    divisionE() {
      return this.$store.getters.divisionStrE
    },
    experience() {
      this.edu = this.$store.getters.experience[0]
      this.edu = this.edu.title
      this.exper = this.$store.getters.experience[1]
      this.exper = this.exper.title
      return this.$store.getters.experience
    },
    myProfile: {
      get() {
        //判斷手機號碼是否輸入
        if(this.$store.getters.profile.mobile==''){
          this.activeChangePhone = true
        }
        return this.$store.getters.profile
      },
      set(val) {
        console.log("test val",val)
        this.$store.commit('SET_PROFILE', val)
      }
    },
    partnerid() {
      return this.$store.getters.partner
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    selectDiv1: {
      get() {
        let tmpdivision = this.$store.getters.division
        let tmp = {}
        let tmpArray = []
        tmp = _.find(tmpdivision, function(o) {return o.title == '內科'})
        tmp != undefined ? tmpArray = tmp.di_id.split(",") : tmpArray = []
        return tmpArray
      },
      set(val) {
        this.$store.commit('UPDATE_DIVISION', {type: 1, data: val.toString()})
      }
    },
    selectDiv2: {
      get() {
        let tmpdivision = this.$store.getters.division
        let tmp = {}
        let tmpArray = []
        tmp = _.find(tmpdivision, function(o) {return o.title == '外科'})
        tmp != undefined ? tmpArray = tmp.di_id.split(",") : tmpArray = []
        return tmpArray
      },
      set(val) {
        this.$store.commit('UPDATE_DIVISION', {type: 2, data: val.toString()})
      }
    },
    selectDiv3: {
      get() {
        let tmpdivision = this.$store.getters.division
        let tmp = {}
        let tmpArray = []
        tmp = _.find(tmpdivision, function(o) {return o.title == '其他科別'})
        tmp != undefined ? tmpArray = tmp.di_id.split(",") : tmpArray = []
        return tmpArray
      },
      set(val) {
        this.$store.commit('UPDATE_DIVISION', {type: 3, data: val.toString()})
      }
    },
    selectDiv4: {
      get() {
        let tmpdivision = this.$store.getters.division
        let tmp = {}
        let tmpArray = []
        tmp = _.find(tmpdivision, function(o) {return o.title == '中醫'})
        tmp != undefined ? tmpArray = tmp.di_id.split(",") : tmpArray = []
        return tmpArray
      },
      set(val) {
        this.$store.commit('UPDATE_DIVISION', {type: 4, data: val.toString()})
      }
    },
    isDev(){
      return this.$store.getters.isDev
    },
    medicalTitleList() {
      var _self = this
      var medicalTitleList = []
      if(_self.$store.getters.medicalTitleList){
        _self.$store.getters.medicalTitleList.forEach(function(v){
          _self.$i18n.locale == 'en' ?
          medicalTitleList.push({ "id": v.id, "name": v.ename }):
          medicalTitleList.push({ "id": v.id, "name": v.cname });
        })
      }
      return medicalTitleList
    }
  },
  methods: {
    // 刪除服務單位
    close_serviceUnit_name(serviceUnit_name){
      this.$confirm(this.$t('profile.removeServiceUnitComfirm')+'【'+serviceUnit_name+'】?', this.$t('popup.alert'), {
        confirmButtonText: this.$t('popup.confirm'),
        cancelButtonText: this.$t('popup.cancel'),
        type: 'warning'
      }).then(() => {
        deletehospitalmap({"hospital_map":this.serviceUnit.name}).then(()=>{
          this.notify('grey', this.$t('profile.removeServiceUnitSuccessful'), '')
          this.$store.dispatch('fetchProfile')
        })
      })
    },
    // 刪除line_notify_綁定資訊
    // close_revoke_line_notify(){
    //   this.$confirm(this.$t('unbindLineWarningText2'),this.$t('unbindLineWarningText1'), {
    //     confirmButtonText: this.$t('popup.confirm'),
    //     cancelButtonText: this.$t('popup.cancel'),
    //     type: 'warning'
    //   }).then(() => {
    //     RevokeLineaccesstoken().then((resp)=>{
    //       this.$vs.notify({
    //           color: resp.data.status=='OK'?'success':'error',
    //           title: this.$t('SuccessTxt'),
    //           text:  resp.data.message,
    //           position:'top-center'
    //       })
    //       this.$store.dispatch('fetchProfile')
    //     })
    //   })
    // },
    // 綁定line_notify
    // getbindlinenotify(code){
    //   BindLineaccesstoken({code:code}).then((resp)=>{
    //       this.$vs.notify({
    //           color: resp.data.status=='OK'?'grey':'error',
    //           title: this.$t('SuccessTxt'),
    //           text: resp.data.message,
    //           position:'top-center'
    //       })
    //       this.$store.dispatch('fetchProfile')
    //   })
    // },
    //切換中文英文標題語系
    switch_experience(experience_title){
      const statusMap = {
        "學歷": this.$t('profile.education'),
        "經歷": this.$t('profile.experience'),
      }
      return statusMap[experience_title]
    },
    // 寄發驗證碼 開始倒數
    startCountdown: function () {
      this.counting = true;
    },
    // 寄發驗證碼 倒數結束
    handleCountdownEnd: function () {
      this.counting = false
      this.vBtnText = this.$t('profile.mobileVerify')
      if (this.newphone.length == 10){
        this.vBtnDisable = false
      }else{
        this.vBtnDisable = true
      }
    },
    // 寄發手機驗證碼
    getVCode() {
      this.vBtnDisable = true
      this.startCountdown()
      this.vBtnText = ''
      if(this.countryCode =="+886" &&this.newphone.charAt(0)!="0"){
        this.newphone = "0"+this.newphone
      }
      axios.post(process.env.VUE_APP_API_URL_HIS+'/api/getmobileverifycode', {countrycode: this.countryCode, mobile: this.newphone, lang: this.$i18n.locale}).then((res) => {
        if(res.data.status=='OK'){
          this.notify('grey', this.$t('profile.deliverVerificationSuccessful'), '')
        }else{
          this.notify('danger', this.$t('profile.deliverVerificationFailed'), '')
        }
      })
    },
    // 開啟變更密碼視窗
    showChangePassword(){
      this.activeChangePassword = true
    },
    // 開啟變更手機號碼視窗
    showChangePhone(){
      this.activeChangePhone = true
    },
    // 開啟編輯個人資訊視窗
    showEditProfile(){
      this.name = this.myProfile.name
      this.family_name = this.myProfile.family_name
      this.email = this.myProfile.email
      let titleName = this.$i18n.locale=='en' ? this.myProfile.title_ename : this.myProfile.title_cname;
      this.selectedTitle = { "id": this.myProfile.medical_title_id, "name": titleName }
      this.$store.dispatch('fetchMedicalTitle')
      this.activeEditProfile = true
    },
    // 開啟編輯銀行資訊視窗
    showEditBankData(){
      this.bankname = this.myProfile.bankdata.bank_name
      this.branch = this.myProfile.bankdata.branch
      this.address = this.myProfile.bankdata.address
      this.account_name = this.myProfile.bankdata.account_name
      this.account_no = this.myProfile.bankdata.account_no
      this.swift = this.myProfile.bankdata.swift
      if(this.myProfile.bankdata.bank_id!=undefined){
        this.bankid = this.myProfile.bankdata.bank_id
      }else if(this.myProfile.bankdata.bank_id==undefined){
        this.bankid = 612
      }
      if(this.myProfile.bankdata.bank_kind!=undefined){
        this.bankkind = this.myProfile.bankdata.bank_kind
      }else if(this.myProfile.bankdata.bank_kind==undefined){
        this.bankkind = 0
      }
      this.activeEditBankData = true
    },
    // 開啟編輯學經歷視窗
    showEditExperience(){
      this.itemValues1 =this.experience[0].subtitle.map((item) => item.value);
      this.itemValues2 = this.experience[1].subtitle.map((item) => item.value);
      // var _self = this
      // let array
      //     // array = v.subtitle.split(",")
      //     if(v.title=='學歷'){
      //       _self.education1 = array[0]
      //       _self.education2 = array[1]
      //       _self.education3 = array[2]
      //       _self.education4 = array[3]
      //       _self.education5 = array[4]
      //       _self.education6 = array[5]
      //     }else if(v.title=='經歷'){
      //       _self.experience1 = array[0]
      //       _self.experience2 = array[1]
      //       _self.experience3 = array[2]
      //       _self.experience4 = array[3]
      //       _self.experience5 = array[4]
      //       _self.experience6 = array[5]
      // }
      this.activeEditExperience = true
    },
    // 開啟編輯專長視窗
    showEditDivision(){
      this.activeEditDivision = true
    },
    // 修改密碼
    updatePW(){
      if(this.newpw.length < 6){
        this.notify('danger', this.$t('profile.changePWFailed1'), '')
        this.activeChangePassword = true
        return false
      }
      if(this.newpw != this.newpwagain){
        this.notify('danger', this.$t('profile.changePWFailed2'), '')
        this.activeChangePassword = true
        return false
      }
      if(/^\d+$/.test(this.newpw)){
        this.notify('danger', this.$t('profile.changePWFailed3'), '')
        this.activeChangePassword = true
        return false
      }
      changePassword({'pw': this.newpw}).then((res) => {
        var _self = this
        if(res.data.status=="OK"){
          _self.$store.dispatch('logout').then(() => {
            this.notify('grey', this.$t('profile.changePWSuccessful'), '')
            console.log('登出 - 密碼變更')
            _self.$router.push('/pages/login').catch(() => {})
            window.reload()
          })
        }else{
          this.notify('danger', this.$t('message.update_error'), '')
        }
      })
    },
    // 變更手機號碼
    updatePH(){
      var color
      var msg
      if(this.countryCode =="+886" &&this.newphone.charAt(0)!="0"){
        this.newphone = "0"+this.newphone
      }
      if(this.newphone.length != 10){
        color="danger"
        msg=this.$t('profile.enterCorrectPhoneNumber')
        this.openAlert_PH(msg)
        return false
      }
      if(this.newphonevalidator.length == 0){
        color="danger"
        msg=this.$t('profile.verificationCodeRequired')
        this.openAlert_PH(msg)
        return false
      }
      editdoctorphone({'countryCode': this.countryCode,"vcode":this.newphonevalidator,"mobile":this.newphone}).then((res) => {
        var _self = this
        if(res.data.status=="OK"){
          _self.$vs.notify({
            color: 'success',
            title: _self.$t('message.update_success'),
            position:'top-center'
          })
        _self.$store.dispatch('fetchProfile')
        _self.newphone=''
        _self.newphonevalidator=''
        }else{
          var color="danger"
          _self.openAlert_PH(color, res.data.message)
          return false
        }
      })
    },
    // 關閉vs-prompt視窗
    close(){},
    // 手機號碼更新失敗視窗
    openAlert_PH(msg){
      this.$vs.dialog({
        title: this.$t('popup.alert'),
        text: msg,
        accept: this.acceptAlert_PH_OK
      })
    },
    // 手機號碼更新失敗視窗確認後 返回變更手機號碼視窗
    acceptAlert_PH_OK(){
      this.activeChangePhone = true
    },
    // 取服務單位資料
    getHospital(){
      var _self = this
      fetchHospital().then((res) => {
        // console.log('hospitalList ', res)
        res.data.data.map(function(v){
          _self.hospitalList.push({
            id: v.hid,
            label: (v.name==null)? '': v.name,
            code: v.code,
            address: v.address,
            icon: "HomeIcon"
          })
        })
        _self.pages.data = _self.hospitalList
      })
    },
    // 刪除服務列表視窗
    delWord(item){
      var _self = this
      _self.delid = item.id
      _self.$vs.dialog({
        type:'confirm',
        acceptText:_self.$t('popup.update'),
        cancelText:_self.$t('popup.cancel'),
        title: _self.$t('popup.confirm'),
        text: _self.$t('message.confirm_delete_record'),
        accept:_self.acceptDel
      })
    },
    // 開啟服務列表編輯視窗
    showEditDialog(item){
      this.editword = item.word
      this.editid = item.id
      this.activeEdit = true
      console.log(this.activeEdit)
    },
    // 更新服務列表
    acceptEdit(){
      var _self = this
      _self.$vs.loading()
      _self.$store.dispatch('editWord', {'id': _self.editid, 'word': _self.editword, 'type': 'service'})
        .then((res) => {
          console.log('edit word  ', res)
          _self.activeEdit = false
          _self.fetchWords()
          _self.$vs.loading.close()
        })
    },
    // 刪除服務列表
    acceptDel(){
      var _self = this
      _self.$vs.loading()
      _self.$store.dispatch('delWord', {'id': _self.delid, 'type': 'service'})
        .then((res) => {
          // console.log('edit word  ', res)
          if(res.data.status=='OK'){
            _self.$vs.notify({
              color:'grey',
              title:_self.$t('message.send_success'),
              text:_self.$t('message.delete_success'),
              position:'top-center'
            })
          }else{
            _self.$vs.notify({
              color:'danger',
              title:_self.$t('message.send_error'),
              text:_self.$t('message.delete_error'),
              position:'top-center'
            })
          }
          _self.fetchWords()
          _self.$vs.loading.close()
        })
    },
    // 新增服務列表
    addWord(){
      var _self = this
      if(_self.word!=''){
        _self.$vs.loading()
        let arrayPayload = [{'type': 'service', 'word': _self.word}]
        _self.$store.dispatch('addWord', arrayPayload)
          .then(() => {
            _self.word = ''
            _self.fetchWords()
            _self.$vs.loading.close()
        })
      }
    },
    // 取服務列表
    fetchWords() {
      var _self = this
      _self.$store.dispatch('fetchWords', 'service')
        .then((res) => {
          _self.serviceTotal = res.data.total
        })
    },
    // 取精通語言資料
    getServiceLang (){
      var _self = this
      fetchServiceLang().then((res) => {
        // console.log('service lang List ', res)
        res.data.rs.map(function(v){
          _self.serviceLang.push({
            id: v.id,
            label: (v.lang==null)? '': v.lang,
          })
        })
      })
    },
    // 更新諮詢費率
    all_slider_price_change(){
      // 註解為舊程式 待刪除
      // this.$store.dispatch('updateServiceFee', {type: type, fee: fee}).then((res) => {
      //   if(res.data.status=='OK'){
      //     this.$store.dispatch('fetchProfile')
      //   }
      // })
      let payload = {
        'fee': [{
          'general_fee': this.twGeneralFee,
          'additional_fee': this.twAdditionalFee,
          'general_fee_cn': this.cnGeneralFee,
          'additional_fee_cn': this.cnAdditionalFee
        }]
      }
      createDoctorFee(payload)
    },
    // 更新服務方式
    Service(v) {
      // console.log(v);
      this.$store.dispatch('updateMyService', v)
        .then(() => {
          this.$store.dispatch('fetchProfile')
        })
    },
    // 更新精通語言
    mylang(v) {
      this.$store.dispatch('updateMyServiceLang', v)
        .then(() => {
          this.$store.dispatch('fetchProfile')
        })
    },
    // 上傳成功通知
    successUpload(){
      this.$vs.notify({color:'grey',title:'Upload Success',text:this.$t('message.data_apdate_success'),position:'top-center'})
    },
    // 更新成功通知
    successUpdate(){
      this.$vs.notify({color: 'grey',title: this.$t('message.update_success'),position:'top-center'})
    },
    // 執業執照上傳
    handleLicenseSuccess(res, file) {
      this.$store.dispatch('getLicensePhoto').then((res) => {
        this.successUpload()
      })
    },
    // 執業執照上傳 檢查上傳文件
    beforeLicenseUpload(file) {
      const isIMAGE = file.type === 'image/jpeg' || 'image/gif' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isIMAGE) {
        this.$vs.notify({color:'danger',title:this.$t('message.upload_error'),text:this.$t('profile.imgOnlyTips'),position:'top-center'})
      }
      if (!isLt2M) {
        this.$vs.notify({color:'danger',title:this.$t('message.upload_error'),text:this.$t('profile.sizeLimitTips'),position:'top-center'})
      }
      return isIMAGE && isLt2M;
    },
    // 銀行照片上傳
    handleBankSuccess(res, file) {
      this.$store.dispatch('getBankPhoto').then((res) => {
        this.successUpload()
      })
    },
    // 銀行照片上傳 檢查上傳文件
    beforeBankUpload(file) {
      const isIMAGE = file.type === 'image/jpeg' || 'image/gif' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isIMAGE) {
        this.$vs.notify({color:'danger',title:this.$t('message.upload_error'),text:this.$t('profile.imgOnlyTips'),position:'top-center'})
      }if (!isLt2M) {
        this.$vs.notify({color:'danger',title:this.$t('message.upload_error'),text:this.$t('profile.sizeLimitTips'),position:'top-center'})
      }
      return isIMAGE && isLt2M;
    },
    // 個人照片上傳
    handleAvatarSuccess(res, file) {
      this.$store.dispatch('getInfo').then((res) => {
        this.successUpload()
      })
    },
    // 個人照片上傳 檢查上傳文件
    beforeAvatarUpload(file) {
      const isIMAGE = file.type === 'image/jpeg' || 'image/gif' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isIMAGE) {
        this.$vs.notify({color:'danger',title:this.$t('message.upload_error'),text:this.$t('profile.imgOnlyTips'),position:'top-center'})
      }
      if (!isLt2M) {
        this.$vs.notify({color:'danger',title:this.$t('message.upload_error'),text:this.$t('profile.sizeLimitTips'),position:'top-center'})
      }
      return isIMAGE && isLt2M;
    },
    // 手機是否驗證 語系
    getDoctorEnableText(type){
      let statusMap = null
      statusMap = {
        0: this.$t('profile.inProcess'),
        1: this.$t('profile.onBoard'),
      }
      return statusMap[type]
    },
    // 手機國碼語系
    countryCodeTranslation(item){
      if(this.$i18n.locale=='tw'){
        return item.countryName +' ('+item.phoneCode+')'
      }else if(this.$i18n.locale=='cn'){
        return item.countryNameCN +' ('+item.phoneCode+')'
      }else if(this.$i18n.locale=='en'){
        return item.countryCode +' ('+item.phoneCode+')'
      }
    },
    // 更新個人資訊
    updateProfile(){
      const obj = {
        name: this.name,
        family_name:this.family_name,
        email: this.email,
        medical_title_id: this.selectedTitle.id,
      }
      updatePersonalData(obj).then(() => {
        this.$store.dispatch('fetchProfile')
        this.successUpdate()
      })
    },
    // 更新銀行資訊
    updateBankData(){
      insertMyBank({
        'bankid':this.bankid,
        'bank_kind':this.bankkind,
        'branch': this.branch,
        'address': this.address,
        'account_name': this.account_name,
        'account_no': this.account_no,
        'bankcode':this.bankcode,
        'bankname': this.bankname,
        'swift':this.swift
      })
        .then((res) => {
          // console.log(res);
          this.$store.dispatch('fetchProfile')
          this.successUpdate()
        })
    },
    // 更新學經歷
    updateExperience(education,experience){
      // let education = {
      //   'education1': this.education1,
      //   'education2': this.education2,
      //   'education3': this.education3,
      //   'education4': this.education4,
      //   'education5': this.education5,
      //   'education6': this.education6
      // }
      // let experience = {
      //   'experience1': this.experience1,
      //   'experience2': this.experience2,
      //   'experience3': this.experience3,
      //   'experience4': this.experience4,
      //   'experience5': this.experience5,
      //   'experience6': this.experience6,
      // }
      this.$store.dispatch('updateExperience', {'edu': education, 'exper': experience})
        .then(res => {
          // console.log('updateExperience', res)
          if(res.data.status === 'OK'){
            this.$store.dispatch('fetchProfile')
            this.activeEditExperience=false
            this.successUpdate()
          }
        })
    },
    // 更新專長
    updateDivision(){
      updateDivision({'selectDiv1': this.selectDiv1, 'selectDiv2': this.selectDiv2, 'selectDiv3': this.selectDiv3, 'selectDiv4': this.selectDiv4}).then((res) => {
        this.$store.dispatch('fetchProfile')
        this.activeEditDivision=false
        this.successUpdate()
      })
    },
    // 取專長list
    getDivisions() {
      getallDivisions().then(res => {
        let tmpdivision = []
        let tmpdivision1 = []
        let tmpdivision2 = []
        let tmpdivision3 = []
        let tmpdivision4 = []
        if(res.data.status=='OK'){
          tmpdivision = res.data.data
          // console.log("tmpdivision",tmpdivision)
          tmpdivision.forEach(function(v, k){
            if(v.category==1){
              tmpdivision1.push(v)
            }else if(v.category==2){
              tmpdivision2.push(v)
            }else if(v.category==3){
              tmpdivision3.push(v)
            }else if(v.category==4){
              tmpdivision4.push(v)
            }
          })
          this.divisions1 = tmpdivision1
          this.divisions2 = tmpdivision2
          this.divisions3 = tmpdivision3
          this.divisions4 = tmpdivision4
        }
      })
    },
    // 背景scrollbar開關
    backgroundScrollbarSwitch(val){
      document.body.style.overflow = val ? 'hidden' : 'scroll';
    },
    // 視窗通知
    notify(color, title, text){
      this.$vs.notify({
        color: color,
        title: title,
        text: text,
        position:'top-center'
      })
    },
    // IOS Keyboard無法跳出 修復方法 
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const title = document.querySelector('#title')
          const timer = setTimeout(() => {
            title.removeAttribute('readonly')
            clearTimeout(timer)
          },200)
        }
      })
    },
    showExperience(experience){
      var show = ''
      experience.forEach(function(v, k){
        if(experience.length>k+1 && v.value){
          show += v.value+','
        }else{
          show += v.value
        }
      })
      return show
    }
    // 選擇Line通知聊天室選擇
    // chosen_notify_chat(){
    //   location.href =
    //     "https://notify-bot.line.me/oauth/authorize?" +
    //     "response_type=code" +
    //     "&client_id=oXJEzyYHmVKLPMIxitFZkf" +
    //     "&redirect_uri="+window.location.href.split('?')[0] +
    //     "&scope=notify" +
    //     "&state=abcd";  //自定義String防止CSRF攻擊
    // },
  },
  beforeRouteLeave (to, from, next) {
    if(this.myProfile.mobile==''&&this.myProfile.verify<3){
      let parnterList = JSON.parse(localStorage.getItem('partnerlist'))
      this.$vs.dialog({
          color: 'primary',
          title: this.$t('popup.alert'),
          text: this.$t('profile.phoneReqiredTips', {name: _.find(parnterList, {'id': this.partnerid}).company}),
          acceptText: this.$t('popup.confirm')
        })
      return false
    }else{
      this.activeChangePassword=false
      this.activeChangePhone=false
      this.activeEditProfile=false
      this.activeEditBankData=false
      this.activeEditExperience=false
      this.activeEditDivision=false
      next()
    }
  },
}
</script>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>
<style>
  /* 照片上傳前的預設圖片 */
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  /* 上傳的照片 */
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .vs-list--item .list-titles .vs-list--subtitle {
    font-size: 1rem;
  }
  /* 服務列表高度 */
  .ps3{
    height: 215px;
  }
</style>